export default function patchMithril(_global) {
  const mo = _global.m;

  // reconstruct the `m` function
  const m = function (comp) {
    // no manipulation for components, classes or factories
    if (_.isFunction(comp.view) || _.isFunction(comp))
      return mo.apply(this, arguments);

    // extract attrs, if exist
    let attrs = arguments.length > 1 ? arguments[1] : null;
    attrs = (attrs != null && _.isPlainObject(attrs) && !('tag' in attrs || 'view' in attrs))
      ? attrs : {};

    // Bypass render
    const shouldRender = attrs.renderIf == null || attrs.renderIf;
    delete attrs.renderIf; // clean up
    if (!shouldRender)
      return null; // render nothing

    // Merge stacked attributes and mixins before create `vnode`
    attrs.className = classString(attrs.className, attrs.class, attrs.layout);
    attrs.Style = styleString(attrs.style, attrs.Style);

    // resolve href link
    if (attrs.href && attrs.externalLink !== true && attrs.href[0] === '/')
      attrs.oncreate = m.route.link;

    // clean up unused attrs
    if (_.isEmpty(attrs.className)) delete attrs.className;
    if (_.isEmpty(attrs.Style)) delete attrs.Style;
    if (_.isEmpty(attrs.style)) delete attrs.style;
    if (attrs.externalLink != null) delete attrs.externalLink;

    // fix chrome v74 update, 'CSSStyleDeclaration': Index property setter is not supported.
    // - this is due to the 'style' key is reserved for CSSStyleDeclaration used
    //   that is conflicting with the objects. Since we already have 'Style' to
    //   preserve the style of the object, we can safely delete style for this purpose.
    if (attrs.style) delete attrs.style;

    // Create `vnode` using original mithril function
    const vnode = mo.apply(this, arguments);
    return vnode;
  };

  // Copy functionalities from original m() to the new m()
  _.keys(mo).forEach(key => m[key] = mo[key]);

  _global.m = m;

  /**
   * Merge all CSS strings in multi level nested array
   *
   * Can process '.aaa.bbb', 'aaa bbb', 'aaa,bbb'
   *
   * E.G: mixClasses('', 'a', ['b.c','d e', 'f,g'], [['h.i j,k'], '  l,,,m...n  o p  ', 'q'],[], 'r', ' ,.')
   * returns ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r']
   */
  function mixClasses(...classes) {
    classes = _
      // flatten into array with only strings
      .flattenDeep(classes)
      // process object string
      .map(cssToString)
      // process css class separated by space, comma and dot
      .join(' ').replace(/[,.]/g, ' ').split(/\s+/g);

    // remove falsy elements ('', null, undefined) and repeated classes
    classes = _.uniq(_.compact(classes));
    return classes;
  }

  /**
   * Calles `mixClasses` internally, and return as string
   */
  function classString(...args) {
    let classes = mixClasses(...args);
    return _.compact(classes).join(' ') || undefined;
  }

  /**
   * Convert CSS class array/object into string
   * Supports conditional class for object key
   *
   * Return input if input is string.
   */
  function cssToString(input) {
    if (_.isString(input)) return input;

    if (_.isObject(input))
      return _.keys(input)
        .filter(key => !!input[key])
        .map(key => _.isString(input[key]) ? input[key] : key)
        .join(' ');


    if (_.isArray(input))
      return input.filter(function (item) {
        return !!item;
      }).join(' ');


    return '';
  }

  /**
   * Merge styles string and objects
   *
   * @return Return an style object that can be accepted by Mithril
   * E.G. the object for m('div', {style: {textAlign: 'center'}});
   */
  function mixStyles(...styles) {
    // flatten into array with only strings or style object
    styles = _.flattenDeep(styles);

    // convert Style Object back into string
    styles = styles.map(function (style) {
      if (_.isObject(style))
        return _.keys(style).map(key => key + ':' + style[key]).join(';');

      return style;
    });

    // split into individual style key value pair in strings
    styles = styles.join(';').split(/;+/);

    const styleMap = {};
    styles.forEach(function (style) {
      const kvp = style.split(':');
      if (kvp.length !== 2) return;

      let [key, value] = kvp;
      if (!key || !value) return;

      [key, value] = [key.trim(), value.trim()]; // trim

      // styles in behind will override the early style
      styleMap[_.kebabCase(key)] = value;
    });

    return styleMap;
  }

  /**
   * Calles `mixStyles` internally, and return as string
   */
  function styleString(...args) {
    let styles = mixStyles(...args);
    return _.keys(styles).map(key => key + ':' + styles[key]).join(';') || undefined;
  }
}
