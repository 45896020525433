import patchMithril from './patchMithril';

class App {
  constructor() {
    this._initializers = {};
    this._bootOrder = [];
    this.routes = {};
    this.session = {};
    patchMithril(window);
  }

  initializer(name, run) {
    let ready = false;
    let callbackQueue = [];

    const loader = {
      name, run,
      done() {
        ready = true;
        callbackQueue.forEach(callback => callback());
        callbackQueue = [];
      },
      onready(callback) {
        if (ready) return callback();
        else callbackQueue.push(callback);
      },
    };

    this._bootOrder.push(loader);
    this._initializers[name] = loader;
  }

  /**
   * Wait for one/many/all initializers to complete, and execute callback
   *
   * ready(name, callback) - pass a name to wait for a particular initializer
   * ready([], callback) - pass an array of name to wait for multiple initializers
   * ready(callback) - wait for all initializers. cannot be called in initializers' code as it will be waiting indefinitely.
   */
  ready() {
    // app.ready(callback)
    if (arguments.length === 1 && _.isFunction(arguments[0])) {
      const callback = arguments[0];
      // wait for all initializers
      const names = _.keys(this._initializers);
      // call self with different params
      this.ready(names, callback);
    }

    if (arguments.length === 2 && _.isFunction(arguments[1])) {
      const callback = arguments[1];

      if (_.isArray(arguments[0])) {
        // app.ready(names, callback)
        const names = arguments[0];

        let count = names.length;
        const waitAllAndRun = () => {
          count--;
          if (count === 0) callback();
        };
        names.forEach(name => {
          this._initializers[name].onready(waitAllAndRun);
        });
      } else {
        // app.ready(name, callback)
        const name = arguments[0];
        this._initializers[name].onready(callback);
      }
    }
  }

  boot() {
    this._bootOrder.forEach(initializer => {
      initializer.run(this, initializer.done);
    });
  }

  setTitle(title) {
    document.title = (title ? title : '');
  }
}

export default App;
