import '../mapit-jslib-jp/client-common/scss/main.scss';
import './scss/user.scss';
import '@mdi/font/scss/materialdesignicons.scss';
import 'jquery-ui-bundle/jquery-ui.css';
import '../mapit-jslib-jp/client-common/js/component/jscolor.js';
window.$ = window.jQuery = require('jquery');
require('jquery-ui-bundle');
require('bootstrap');
window._ = require('lodash');
window.postal = require('postal');
window.m = require('mithril');
m.prop = require('mithril/stream');
