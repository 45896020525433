import './vendor';
import App from '../mapit-jslib-jp/client-common/js/util/App';
import boot from './service/boot';
import routes from './service/routes';
import request from './service/request';
// import ga from './service/googleAnalytics';

window.app = new App();
// app.initializer('ga', ga);
app.initializer('routes', routes);
app.initializer('request', request);
app.initializer('boot', boot);

app.boot(); // Run all the initializers!
