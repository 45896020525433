export default function routes(app, done) {
  app.routes = {
    /**
     * For standalone journey planner
     */
    '/': routeResolver(import('../js'), 'JourneyPlannerPage'),
    '/fare_calculator': routeResolver(import('../js'), 'FareCalculatorPage'),
  };

  function routeResolver(pages, page) {
    const onmatch = (args, requestedPath, route) => {
      console.log('match: ', args, requestedPath, route);
      return Promise.resolve(pages)
        .then(pages => {
          // app.ga.pageview(requestedPath, [], page);
          return page ? pages[page] : pages;
        });
    };

    return { onmatch };
  }

  done();
}
