import Component from 'core/Component';

export default function ModalManager() {
  const service = new ModalManagerService();
  const component = ModalManagerComponent;
  const modal = _.assign({
    show: service.show.bind(service),
    close: service.close.bind(service),
  }, { component });
  return modal;
}

/**
 * The `ModalManager` component manages a modal dialog. Only one modal dialog
 * can be shown at once; loading a new component into the ModalManager will
 * overwrite the previous one.
 */

class ModalManagerService {
  constructor() {
    this.modalManager = postal.channel('modal.manager');
  }

  show() {
    this.modalManager.publish('modal.show', arguments);
  }

  close() {
    this.modalManager.publish('modal.close');
  }
}

class ModalManagerComponent extends Component {
  oninit(vnode) {
    super.oninit(...arguments);

    this.showing = false;
    this.component = null;
    this.modalOptions = null;
  }

  oncreate(vnode) {
    super.oncreate(...arguments);

    const $element = $(vnode.dom);
    $element
      .on('hidden.bs.modal', this.onhide.bind(this, vnode))
      .on('shown.bs.modal', this.onready.bind(this, vnode));

    const subscription = this.subscription = {};
    subscription.show = postal.subscribe({
      channel: 'modal.manager',
      topic: 'modal.show',
      callback: (data, envelope) => { this.show(vnode, ...data); },
    });
    subscription.close = postal.subscribe({
      channel: 'modal.manager',
      topic: 'modal.close',
      callback: (data, envelope) => { this.close(vnode); },
    });
  }

  onremove(vnode) {
    super.onremove(...arguments);
    this.subscription.show.unsubscribe();
    this.subscription.close.unsubscribe();
  }

  /**
   * Clear content from the modal area
   *
   * @protected
   */
  onhide(vnode) {
    this.component = null;
    this.modalOptions = null;
    this.showing = false;
    $(vnode.dom).removeData('bs.modal'); // to reset so that jquery config is reset

    m.redraw();
  }

  /**
   * When the modal dialog is ready to be used
   *
   * @protected
   */
  onready(vnode) {
  }

  view(vnode) {
    return m('.modal', [
      this.component ? this.component.build(this.modalOptions) : '',
    ]);
  }

  /**
   * Show a modal dialog.
   *
   * @param {Modal} component
   * @public
   */
  show(vnode, component, options = {}) {
    clearTimeout(this.hideTimeout);

    this.showing = true;
    this.component = component;
    this.modalOptions = options;

    const _options = {
      backdrop: (options.dismissible == null || options.dismissible) ? true : 'static',
    };

    $(vnode.dom).modal(_options);
  }

  /**
   * Close the modal dialog.
   *
   * @public
   */
  close(vnode) {
    if (!this.showing) return;

    // Don't hide the modal immediately, because if the consumer happens to call
    // the `show` method straight after to show another modal dialog, it will
    // cause Bootstrap's modal JS to misbehave. Instead we will wait for a tiny
    // bit to give the `show` method the opportunity to prevent this from going
    // ahead.
    this.hideTimeout = setTimeout(() => {
      $(vnode.dom).modal('hide');
      $(vnode.dom).removeData('bs.modal'); // to reset so that jquery config is reset
      this.showing = false;
    });
  }
}
