import ModalManager from '../../mapit-jslib-jp/client-common/js/core/ModalManager';

export default function boot(app, done) {
  let id = _id => document.getElementById(_id);

  renderPlaceHolder();
  mountLayout();
  mountRoute();
  done();

  function renderPlaceHolder() {
    m.render(document.body, [
      m('#app'),
      m('#modal'),
    ]);
  }

  function mountLayout() {
    app.modal = ModalManager();

    m.mount(id('modal'), app.modal.component);
  }

  function mountRoute() {
    m.route.prefix('#');
    m.route(document.getElementById('app'), '/', app.routes);
  }
}
