export default function request(app, done) {
  const request = app.request = {};

  // const setAuthHeader = (xhr) => xhr.setRequestHeader('Authorization', 'Basic ' + app.session.auth);

  request.gql = (query) => {
    const url = '/endpoint/gql';
    return m.request({
      // config: setAuthHeader,
      method: 'get', url, data: { query },
    });
  };

  request.epi = (method, table, data) => {
    const url = '/endpoint/epi/' + table;
    return m.request({
      // config: setAuthHeader,
      method, url, data,
    });
  };

  request.mongo = (method, model, data) => {
    const url = '/endpoint/mongo/' + model;
    return m.request({
      // config: setAuthHeader,
      method, url, data,
    });
  };

  request.custom = (method, module, endpoint, data) => {
    const url = '/endpoint/' + module + '/' + endpoint;
    return m.request({
      // config: setAuthHeader,
      url, method, data,
    });
  };

  request.download = (filename, url, data) => {
    return m.request({
      // config: setAuthHeader,
      method: 'get', url, data,
      deserialize: value => value,
    }).then(_data => {
      // create a blob url representing the data
      const blob = new Blob([_data]);
      // create a blob url representing the data
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.click();
      URL.revokeObjectURL(a.href);
    });
  };

  request.downloadBlob = (filename, url, data) => {
    // http://keyangxiang.com/2017/09/01/HTML5-XHR-download-binary-content-as-Blob/
    // NOTE: was initially tested using m.request(). but the response did not seems to
    //       return coorect value. Thus, revert to using XMLHttpRequest, which seem to work
    //       correctly for the zip file.
    //       Also to note, the above download seems to work for text base file. Leaving as it is.
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      // setAuthHeader(xhr);

      // Now set response type
      xhr.responseType = 'arraybuffer';
      xhr.onload = () => resolve(xhr.response);
      xhr.onerror = () => reject(xhr.response);
      xhr.send();
    })
      .then(arraybuffer => {
        // This is the recommended method:
        const blob = new Blob([arraybuffer], { type: 'application/octet-stream' });

        // create a blob url representing the data
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = filename;
        a.click();
        URL.revokeObjectURL(a.href);
      });
  };

  // app.ready('session', () => {
  //   Promise.all([
  //     app.request.custom('get', 'settings', 'specialApikey')
  //       .then(data => ({ user: data.user.username, key: data.key }))
  //       .then(data => app.session.apikey = data)
  //       .catch(err => {}),
  //     app.request.custom('get', 'settings', 'permission/me')
  //       .then(data => app.session.p = data),
  //   ])
  //     .then(() => done())
  //     .catch(err => done());
  // });
}
