export default class Component {
  /** mithril lifecycle */
  oninit(vnode) {}

  oncreate(vnode) {}

  onupdate(vnode) {}

  onbeforeremove(vnode) {}

  onremove(vnode) {}

  onbeforeupdate(vnode, old) {}

  view(vnode) {
    throw new Error('Component#view must be implemented by subclass');
  }

  static build(props = {}, children = null) {
    return m(this, props);
  }
}
